.Port-card-1 {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
}

.port-data {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #000000a4;
  transition: all 0.3s;
  transform: translateY(100px);
  opacity: 0;
}
.Port-card-1:hover {
  background-color: #fff;
}
.Port-card-1:hover .port-data {
  opacity: 1;
  transform: translateY(0px);
  padding-top: 80px !important;
}

.Port-card-1:hover .Port-card-1 .port-data {
  opacity: 1;
  transform: translateY(0px);
  padding-top: 40px !important;
}
#portpli li a {
  font-size: 15px !important;
  font-weight: 500;
}
