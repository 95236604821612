.facebook-icon i {
  color: #3b5998 !important;
}
.instagram-icon i {
  /* Use gradient background */
  background: linear-gradient(
    to right,
    #833ab4,
    #fd1d1d,
    #fd1d1d,
    #fcb045
  ) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}
.linkedin-icon i {
  color: #0077b5 !important;
}

/* YouTube */
.youtube-icon i {
  color: #ff0000 !important;
}
.gradient-line {
  background: linear-gradient(
    to right,
    #0077b5,
    #0077b5,
    #fd1d1d,
    #fcb045
  ) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}
.line {
  width: 80px;
  height: 1px;
  background: linear-gradient(
    to right,
    #0077b5,
    #0077b5,
    #fd1d1d,
    #fcb045
  ) !important;
}

.container-box {
  padding-left: 4%;
  padding-right: 4%;
}

.paragraph {
  font-size: 18px;
}

/* .text-green {
  color: #29b350 !important;
} */

.bg-green {
  background-color: #29b350 !important;
}

.button {
  padding: 16px 30px;
  background-color: aliceblue;
  color: #222;
  text-decoration: none;
}

.bg-light-gray {
  background-color: #efefef !important;
}

/* top heading */
.section-title .description {
  letter-spacing: 3px;
  font-size: 14px;
  font-weight: 400;
}

.title-h2 {
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 50px;
}

/* arror animation */
.arrow-animation {
  display: inline-block;
  animation: slideAni 1s ease-in-out infinite;
}

@keyframes slideAni {
  0%,
  100% {
    transform: translate(0, 0);
    opacity: 0.1;
  }

  50% {
    transform: translate(10px, 0);
    opacity: 1;
  }
}

/* bordered text */
.bordered-text {
  color: transparent;
  -webkit-text-stroke: 1.5px #222;
  -webkit-text-fill-color: transparent;
  font-size: 150px;
  font-weight: 600;
  line-height: 90px;
}

.green-border {
  -webkit-text-stroke: 2.5px #29b350 !important;
  -webkit-text-fill-color: #222;
}

.head-2 {
  font-size: 50px;
}

.btn-green {
  background-color: transparent !important;
  border: 1px solid #29b350;
  color: #29b350 !important;
  transition: all 0.4s;
}

.btn-green:hover {
  background-color: #29b350 !important;
}

/* Home 2 ===================================================*/
.heading-top p {
  color: #646464;
  margin-bottom: 8px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.heading-top p::before {
  content: "";
  width: 10px;
  height: 12px;
  display: inline-block;
  background-color: #222;
  margin-right: 10px;
}

.heading-top h3 {
  font-size: 44px;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.sm-heading::after {
  content: "";
  width: 10px;
  height: 12px;
  display: inline-block;
  background-color: #222;
  margin-left: 10px;
}

.letter-spacing {
  letter-spacing: 1px;
}
#privacy-content p {
  color: #646464 !important;
  font-size: 16px !important;
}
#privacy-content h3 {
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 25px !important;
  text-transform: uppercase !important;
}

.paragraph-2 {
  color: #646464;
  font-size: 16px;
}
.paragraph-2 .paragraph {
  color: #646464;
  font-size: 16px;
}
/* style-btn */
.style-btn {
  padding: 15px;
  text-decoration: none;
  color: #222;
  position: relative;
}

.style-btn span {
  display: inline-block;
  margin-right: 20px;
  padding-left: 22px;
  position: relative;
  z-index: 1;
  font-weight: 700;
}

.style-btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  background-color: #fff;
  border-radius: 50px;
  z-index: 0;
  transition: all 0.4s;
}

.style-btn div {
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.style-btn:hover::before {
  width: 100%;
}

.style-btn-3 {
  color: #f2f2f2;
}

.style-btn-2::before {
  background-color: #f2f2f2;
}

.style-btn:hover {
  color: #222 !important;
}

.style-btn-3 span {
  color: #222;
}

.fixed-social-icons {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 35%;
  left: -10.5%;
  transform: rotate(90deg);
}

.fixed-social-icons i {
  color: #fff;
  /* background: linear-gradient(to right, #833ab4, #fd1d1d, #fd1d1d, #fcb045); */
  margin: 0 8px;
  transform: rotate(-90deg);
  display: inline-block;
}

.fixed-social-icons i:hover {
  color: #29b350;
}

/* .line {
  width: 80px;
  height: 1px;
  background-color: #fff;
} */

.fixed-social-icons span {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.bg-dots {
  background-image: url("https://theme.dsngrid.com/blackdsn/light/assets/img/bg-dot-light.png") !important;
  background-size: cover;
}

.slider-btns button {
  padding: 10px 20px;
  border: none;
  font-size: 26px;
  background-color: #fff;
  color: var(--bg-dark);
}

.slider-btns button:hover {
  color: var(--green-color);
}

.slider-btns button:active {
  transform: scale(1.04);
}

.accordion-button {
  box-shadow: none !important;
}

.padding-banner {
  padding-bottom: 7rem !important;
}

@media only screen and (max-width: 600px) {
  .d-xs-block {
    display: block !important;
    margin-bottom: 1.5rem;
  }
  .all-blog-button a {
    padding-left: 0 !important;
    /* padding-top: 0.5rem; */
  }

  .pfmb {
    width: 100%;
    height: 400px;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .pfmb {
    width: 100%;
    height: 400px;
    margin-bottom: 3rem;
    padding-right: 1rem !important;
  }
  .pfmbspace {
    padding: 0 1rem !important;
  }
}

@media (width >=1700px) {
  .paragraph,
  .paragraph-2,
  .privacy {
    font-size: 20px !important;
  }

  .container-box {
    padding-left: 8%;
    padding-right: 8%;
  }

  .fixed-social-icons {
    left: -6%;
  }
}

@media (max-width: 768px) {
  .heading-top h3 {
    font-size: 30px;
  }

  .head-2 {
    font-size: 30px;
  }

  .slider-btns button {
    font-size: 19px;
  }
  .bordered-text {
    font-size: 100px;
  }
  .footer-heading h3 {
    font-size: 22px;
  }
}
