:root {
  --green-color: #29b350;
  --dark-blue: #252161;
}

/* navbar */
.navbar-links .navlinks .active-link {
  color: #29b350 !important;
}
.pd-navbar {
  padding: 10px 0px;
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}

.skicky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadedownNav 0.8s ease-in-out 0s 1 normal none running;
  box-shadow: 2px 2px 10px #33333324;
  z-index: 999;
  background-color: #fff;
}

@keyframes fadedownNav {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.skicky-navbar .navlinks li a {
  color: #222 !important;
}

.navlinks {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  position: relative;
}

.navlinks > li > a:hover {
  color: #29b350 !important;
}

.navlinks li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  display: block;
  padding: 14px 15px;
}

.navlinks-2 > li > a {
  padding: 14px 18px;
}

.inner-links {
  position: absolute;
  top: 101%;
  min-width: 200px;
  background-color: #fff;
  color: white;
  list-style: none;
  display: none;
  padding-left: 0;
  z-index: 20;
  animation: innerLinksAni 0.3s ease-in-out 0s 1 normal none running;
  box-shadow: 1px 1px 10px #11111152;
}

.inner-links > li > a {
  color: #252161;
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.4s;
}

.inner-links > li > a:hover {
  background-color: #29b350;
  padding-left: 30px;
  color: #fff;
}

.navlinks > li:hover .inner-links {
  display: block;
}

@keyframes innerLinksAni {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* ================================= */

.main-banner-content {
  padding: 20px;
  text-align: center;
}

.main-banner-content h3 {
  color: white;
  font-size: 50px;
  font-weight: 650;
}

.main-banner-content p {
  color: white;
  position: relative;
  font-size: 16px;
}

/* carousel */

.carousel-slide {
  position: relative;
  z-index: 0;
  height: 100vh;
}

.carousel-slide > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.carousel-btns {
  position: absolute;
  bottom: 8%;
  width: 85%;
  left: 7.5%;
  text-align: center;
}

.carousel-btns button {
  border: none;
  height: 18px;
  width: 18px;
  margin: 0 5px;
  background-color: #fff;
  transition: all 0.4s;
}

.currSlide {
  background-color: #29b350 !important;
}

.carousel-content-btn {
  padding: 15px 30px;
  margin-right: 20px;
  background-color: #164ba0;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.carousel-content-btn:hover {
  background-color: #3874f5 !important;
}

.banner-content {
  color: #fff;
  padding: 40px;
  padding-bottom: 60px;
}

.banner-content h1 {
  font-size: 80px;
  font-weight: 600;
}

.banner-content h3 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 1px 1px 5px #000000a4;
}

.banner-content p {
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}

/* new section */
.our-works {
  display: flex;
  justify-content: center;
  background-color: #efefef;
  padding: 13px 30px;
  border-radius: 8px;
}

.our-works i {
  font-size: 55px;
  color: #29b35076;
}

.our-works > div {
  padding-left: 30px;
}

.our-works > div h4 {
  font-size: 22px;
}

.our-works > div p {
  color: #999;
}

/* servivces */
.service-card {
  margin-right: 30px;
}

.service-card div img {
  height: 340px;
  object-fit: cover;
}

.service-card-content h5 {
  font-size: 25px;
}

.service-card-content a {
  font-size: 19px;
  text-decoration: none;
  color: #252161;
}

/* cta section */
.contact-bg {
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}
.bd-menu-list i {
  font-size: 30px;
  color: white;
}
.skicky-navbar .bd-menu-list i {
  color: black;
}

.cta-section {
  color: white;
}

.cta-section h2 {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 30px;
}

/* blogs cards */
.blog-card {
  position: relative;
}

.blog-card div .hover-box {
  position: absolute;
  height: 340px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #25216139;
  color: #fff;
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 19px;
}

.blog-card .service-card-content {
  padding: 20px 20px;
  background-color: #f2f2f2;
  margin-top: 0 !important;
}

.blog-card .service-card-content p {
  color: #222;
}

.blog-card .service-card-content a {
  color: #29b350;
}

/* partners */
.p-logos > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.p-logos img {
  width: 110px;
}

/* first servcice */
.service-card-1 {
  transition: all 0.5s;
  height: 275px;
  position: relative;
  overflow: hidden;
}

.service-card-1:hover {
  background-color: #fff;
}

.service-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-data {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #000000a4;
  transition: all 0.3s;
  padding: 20px;
  transform: translateY(100px);
  opacity: 0;
}
.banner-effect {
  background-color: #0000005e;
}

.service-card-1:hover .service-data {
  opacity: 1;
  transform: translateY(0px);
  padding-top: 80px !important;
}

.service-card-1 h5 {
  font-size: 25px;
}

.service-card-1 a {
  font-size: 19px;
  text-decoration: none;
  color: #29b350;
}
.service-card-1 span,
.service-card-1 i {
  font-size: 19px;
  text-decoration: none;
  color: #29b350;
}

/* footer */
.footer {
  background-color: var(--dark-blue);
}

.footer-links a {
  display: block;
  padding: 2px 0;
  color: white;
  transition: 0.4s;
  margin-bottom: 8px;
  text-decoration: none;
  font-size: 16.5px;
  font-weight: 500;
}

.footer-links a:hover {
  color: var(--green-color);
}
.text-white {
  color: white;
}

.social-links {
  display: flex;
}

.social-links a {
  padding: 5px 9px;
  background-color: transparent;
  margin-right: 10px;
  color: white;
  transition: all 0.4s;
  border-radius: 4px;
  font-size: 22px;
}

.policy-terms a:hover {
  color: var(--green-color) !important;
}

.bg-footer {
  background-color: rgb(8, 6, 36, 0.95);
}
.bg-footer .h4 {
  color: white;
}

/*  */

.subscribe_form input {
  background: #fff;
  border: 0;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 600;
  height: 68px;
  padding: 5px 150px 5px 25px;
  width: 100%;
}

.subscribe_form button {
  background-color: #29b350;
  border: none;
  border-radius: 35px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 30px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 9px;
  transition: all 0.4s ease 0s;
  z-index: 1;
}
.bd-service-tab .nav-link {
  font-weight: 600;
}
.bd-service-tab .nav-link:hover {
  color: #29b350;
}

@media (width >=1700px) {
  .service-card-1 {
    height: 520px;
  }

  .service-card div img,
  .blog-card div .hover-box {
    height: 500px;
  }

  .service-data p {
    font-size: 20px;
  }

  .service-data h5 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .navlinks li a {
    font-size: 18px;
  }
}
@media (width >=768px) {
  .cta-long-btn {
    display: none;
  }
  .bd-nav-mobile {
    display: none;
  }
}
.font-19 {
  font-size: 18px;
}

.navlinks .home-link {
  color: white !important;
}

/* mobile ====================== */
@media (width <=768px) {
  .banner-text .col-5 {
    width: 100%;
  }
  .mobile-width {
    width: 100%;
  }
  .cta-input {
    width: 90% !important;
    margin-bottom: 10px;
  }

  .mobile-flex-wrap {
    flex-direction: column;
  }
  .webview {
    display: none;
  }
  .bd-images-row {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .bd-images-row .col-6 {
    width: 100%;
  }
  .bd-images-row img {
    width: 100% !important;
  }
  /* #bd-portfolio-fix img {
    width: 550px !important;
  } */
  .bd-images-row .img-spacing {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }

  .middle-service-card {
    transform: translateY(0) !important;
  }

  .our-works {
    margin-bottom: 30px;
    border-right: none !important;
  }

  .title-h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .service-card {
    margin: 0px 10px;
  }

  .cta-section h2 {
    font-size: 30px;
  }

  .contact-bg {
    background-position: 20% 20%;
    background-repeat: no-repeat;
  }

  .service-card-1:hover .service-data {
    opacity: 1;
    transform: translateY(0px);
    padding-top: 40px !important;
  }
  .bd-blog-btn {
    width: 50%;
    margin-right: 0px !important;
  }
  .service-btn {
    width: 50%;
    margin-right: 0px !important;
  }
  /* Career Page */
  .aplybtn {
    margin-top: 1rem !important;
    margin-bottom: 3rem !important;
  }
}

/* mobile Sidebar */

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: #efefef;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 999;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 27px;
  color: black;
  display: block;
  transition: 0.3s;
}
.sidenav span {
  font-size: 14px;
  opacity: 1;
  color: rgba(black, black, black, 0.5);
}

.sidenav a:hover {
  color: black;
  scale: 1.1;
  transform: translateX(20px);
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.bd-sub-services {
  list-style: none;
  padding-left: 0px;
  height: 0;
  visibility: hidden;
  margin-bottom: 0px;
  transition: 0.3s;
}
.bd-sub-services li a {
  font-size: 20px;
  padding-left: 30px;
}
.bd-sub-services li span {
  font-size: 13px;
}
.bd-blog-btn::before {
  background-color: #f2f2f2 !important;
}

@media only screen and (max-width: 991px) {
  #vh-xs-auto {
    height: unset !important;
  }
  .navlinks li a {
    font-size: 10px;
    padding: 14px 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1177px) {
  .navlinks li a {
    font-size: 13px;
    padding: 14px 8px;
  }
}
@media only screen and (min-width: 1178px) and (max-width: 1365px) {
  .navlinks li a {
    font-size: 16px;
    padding: 14px 8px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1800px) {
  .navlinks li a {
    font-size: 15px;
    padding: 14px 14px;
  }
}

@media only screen and (max-width: 600px) {
  .mx-xs-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .h-xs-lservice {
    height: 165vh !important;
  }
  #vh-xs-auto {
    height: 66vh !important;
  }
  .py-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mt-5 {
    margin-top: 1.5rem !important;
  }
  .mobile-font {
    font-size: 13px !important;
  }
  .ps-xs-4 {
    padding-left: 1.5rem !important;
  }
  .mt-xs-0 {
    margin-top: 0px !important;
  }
  .mt-xs-2 {
    margin-top: 1rem !important;
  }
  .mt-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-1 {
    margin-right: 0.5rem !important;
  }
  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .carousel-slide > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
  .carousel-slide {
    height: 65vh;
  }
  .carousel-slide-middle img {
    object-position: right;
  }
  .calausel-last img {
    object-position: 13% 10% !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 970px) {
  .carousel-slide > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
  .carousel-slide {
    height: 90vh;
  }
  .carousel-slide-middle img {
    object-position: right;
  }
}
