.bd-about-first h1{
    font-size: 32px;
    margin-bottom: 15px;
    text-transform:capitalize
}

.bd-about-first p{
    color: #0009;
    font-size: 16px;
}
.bd-cta-form{
    padding: 40px 25px;
    background-color: #e4dcd3;
    border-radius: 3px;
}
.justify-space-between{
    justify-content: space-between;
}