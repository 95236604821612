#main-card:hover .card-button {
  bottom: 0px;
  z-index: 1;
}
#main-card .card-button {
  bottom: 0px;
  z-index: -1;
}
#main-card .card-img:hover {
  z-index: 11;
  background-color: #000000a4;
}
#main-card .card-img {
  display: flex;
  justify-content: center;
}

/* ProductBottle page Css */

#Productp .decpription h3 {
  font-size: 23px;
}

#Productp .decpription ul li {
  list-style-type: none;
}

/* Product page */

#prod .productName {
  font-weight: 500;
  font-size: 25px;
  color: #222 !important;
}
#prodBottle .productName {
  font-weight: 600;
  font-size: 18px;
  /* color: #252161; */
}
#prodBottle .service-card-1:hover .service-data {
  padding-top: 210px !important;
}

@media only screen and (max-width: 991px) {
  #prod {
    padding: 0 1.5rem;
  }
  .prodmainimg {
    height: 650px !important;
  }
}
@media only screen and (max-width: 600px) {
  #Productp .padding-mb {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
}

/*................. For Bammmer */

.service-data {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #000000a4;
  transition: all 0.3s;
  padding: 20px;
  padding-top: 20px;
  transform: translateY(100px);
  opacity: 0;
}
#prodBottle .service-card-1:hover .service-data {
  padding-top: 210px !important;
}
.service-card-1:hover .service-data {
  opacity: 1;
  transform: translateY(0px);
}
