.bd-contact-from {
  padding: 110px 100px 110px 90px;
  background-color: #efefef;
}
.bd-forms-inputs input {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  border: 1px solid #0000003b;
  padding: 10px 18px;
  color: #000;
  margin-bottom: 0;
  background-color: transparent;
}
.bd-forms-inputs textarea {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  border: 1px solid #0000003b;
  padding: 10px 18px;
  color: #000;
  margin-bottom: 0;
  background-color: transparent;
}
.bd-forms-inputs select {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  border: 1px solid #2828281a;
  padding: 10px 18px;
  color: #000;
  margin-bottom: 0;
  background-color: transparent;
}
.info-details {
  padding: 40px 30px;
  background-color: #e4dcd3;
}
.pr-0 {
  padding-right: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.bd-get-info {
  position: absolute;
  right: 77px;
  width: 30%;
}
.info-details i {
  font-size: 25px;
  color: black;
  font-weight: 550;
  margin-right: 20px;
}
.info-details a {
  text-decoration: none;
  color: #0009;
}
.info-details p {
  text-decoration: none;
  color: #0009;
}
.bd-top-border {
  border-top: 1px solid black;
  text-align: center;
}
.bd-top-border h5 {
  padding-top: 20px;
}
.bd-top-border i {
  font-size: 18px;
  color: black;
  margin-right: 25px;
}
.submit-contact {
  position: relative;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: black;
  padding: 20px 30px;
  cursor: pointer;
  z-index: 9;
  min-width: 150px;
  border: 1px solid #2828281a;
}

@media (width <=768px) {
  .bd-contact-from {
    padding: 30px !important;
  }
  .bd-get-info {
    position: relative;
    right: 0;
    width: 100%;
  }
  .mobile-enquiry {
    display: none;
  }
}
