#blog-bg {
  background-color: #f6f6f6;
}
.content p {
  text-align: justify;
  padding-left: 1%;
}
.service-card {
  margin-right: 10px !important;
}
#ALL-BLOG {
  background-color: #fefefe;
}
#ALL-BLOG .blog-row .col-sm-4 {
  padding-left: 0.4% !important;
  padding-right: 0.4% !important;
}

@media only screen and (max-width: 600px) {
  #blog-bg .row .pe-xs-3 {
    padding-right: 1rem !important;
  }
  .ps-xs-0 {
    padding-left: 0 !important;
  }
}
