.banner-slider {
  background-position: 90% 10%;
  background-size: 113%;
  background-repeat: no-repeat;
}
.banner-slider-2 {
  background-position: 0 25%;
  background-size: cover;
  background-repeat: no-repeat;
}
#thankyou-banner .padding-banner {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}
#thankyou-banner .banner-slider {
  background-position-y: 29% !important;
}
.padding-banner {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.banner-text {
  margin-left: 30px;
}
.text-right {
  text-align: right;
}
.bd-service-head {
  position: absolute;
  bottom: 23px;
  /* color: #646464; */
  color: white;
  text-shadow: rgb(77, 77, 75) 1px 0 10px;
  left: 48px;
}
.cta-input input {
  padding: 15px;
  border: none;
  border-radius: 50px;
  width: 100%;
  padding-left: 40px;
}
.cta-input {
  width: 23%;
  margin-right: 20px;
  position: relative;
}
.cta-input i {
  position: absolute;
  color: black;
  position: absolute;
  color: black;
  font-size: 22px;
  top: 11px;
  left: 10px;
}

.bd-service-tab a {
  font-size: 18px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
}
.bd-service-tab .active {
  color: #a8a8a8;
}
.bd-portfolio-img {
  overflow: hidden;
}
.bd-portfolio-img img {
  width: 500px;
  object-fit: cover;
  height: 360px;
  transition: all 0.6s;
}

.bd-portfolio-img-1 {
  transition: opacity 3s cubic-bezier(0.19, 1, 0.22, 1) 1s,
    transform 2s cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

@media only screen and (max-width: 767px) {
  .servicepimg {
    width: 100%;
    height: 400px;
    margin-bottom: 3rem;
    padding-right: 1rem !important;
  }
  .sppadding {
    padding: 0 1rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .cta-section .style-btn {
    width: 90%;
    text-align: left;
    padding-right: 20px;
    padding-left: 0px;
    padding: 15px 0;
  }
  .banner-slider {
    background-position: center;
    background-size: cover;
  }
  .banner-text h1 {
    font-size: 20px;
  }

  .servicepimg {
    width: 100%;
    height: 400px;
    margin-bottom: 3rem;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
